<template>
  <div :class="['slide-shell','slide-shell-3']">
    <div class="card">
      kris@kriskhoury.com<br>
      312-577-9700
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide3Component',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  },
}
</script>

<style lang="scss">
  .slide-shell-3{
    .card{
      position: absolute;
      top: 200px;
      left: 0;
      right: 0;
      bottom: 150px;
      background-color: white;;
      aspect-ratio: 3.5 / 2;
      width: 50%;
      margin: auto;
      box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 50px;
      line-height: 70px;
      @media (max-width: 700px) {
        width: 80%;
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
</style>
