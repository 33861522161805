import Vue              from 'vue'
import Vue2TouchEvents  from 'vue2-touch-events'
import App              from './App.vue'

import { library }      from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGithub, faLinkedin, faMedium, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faArrowLeft, faArrowRight, faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add([faGithub, faLinkedin, faMedium, faInstagram, faArrowLeft, faArrowRight, faEnvelope]);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vue2TouchEvents)

import '@/components'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
