<template>
  <div id="app">
    <v-home-loading v-if="loading"></v-home-loading>
    <div v-else>
      <v-home-header></v-home-header>
      <v-slides></v-slides>
      <!-- <v-book></v-book> -->
      <v-home-footer></v-home-footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      loading: true
    }
  },
  created(){
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Onest:wght@400;600;900&display=swap');
body{
  padding: 0px;
  margin: 0px;
  background-color: white;
  background-image: url("https://www.transparenttextures.com/patterns/batthern.png");
  font:{
    family: 'Onest', sans-serif;
    weight: 600;
    size: 18px;
  }
  overflow-y: hidden;
}
#app {
  color: #000;
  background-color: #ffffff;
}
</style>
