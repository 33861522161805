<template>
  <div :class="['slide-shell', 'slide-shell-1']">
    <inline-svg :src="require('@/assets/slide-1.svg')"></inline-svg>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'Slide1Component',
  data() {
    return {
      slide: 0,
    }
  },
  components: {
    InlineSvg,
  },
  created(){
  }
}
</script>

<style lang="scss">
  @keyframes swing {
    0%          { transform: rotate(3deg); }
    100%        { transform: rotate(-3deg); }
  }
  @keyframes head_move {
    0%, 20%     { transform: none; }
    21%, 30%    { transform: rotate3d(1, 1, 1, 2deg); }
    31%, 70%    { transform: none; }
    71%, 80%    { transform: rotate3d(1, 1, 1, -1deg); }
    81%, 97%    { transform: rotate(360deg); }
    98%, 100%   { transform: none; }
  }
  @keyframes eye_blink {
    3%          { opacity: 0; }
    5%          { opacity: 1; }
    6%          { opacity: 0; }
  }
  @keyframes eye_move {
    0%, 19%     { transform: none; }
    20%, 48%    { transform: translateX(0px) translateY(2px); }
    50%, 100%   { transform: none; }
  }
  @keyframes eyebrow_move {
    0%, 19%     { transform: none; }
    20%, 48%    { transform: translateY(7px); }
    60%, 78%    { transform: translateY(-4px); }
    80%, 100%   { transform: none; }
  }
  @keyframes mouth_move {
    0%, 19%     { transform: none; }
    20%, 23%    { transform: translateY(10px); }
    24%, 100%   { transform: none; }
  }
  @keyframes tongue_out {
    0%, 19%     { transform: none; }
    20%, 23%    { transform: translateY(20px); }
    24%, 100%   { transform: none; }
  }
  @keyframes light_flicker {
    10%         { opacity: 1; }
    11%         { opacity: 0; }
    20%         { opacity: 1; }
    21%         { opacity: 0; }
    30%         { opacity: 1; }
    31%         { opacity: 0; }
    40%         { opacity: 1; }
    41%         { opacity: 0; }
    50%         { opacity: 1; }
    51%         { opacity: 0; }
    60%         { opacity: 1; }
    61%         { opacity: 0; }
    70%         { opacity: 1; }
    71%         { opacity: 0; }
    80%         { opacity: 1; }
    81%         { opacity: 0; }
    90%         { opacity: 1; }
    91%         { opacity: 0; }
  }
  @keyframes chemex_steam {
    0%          { filter: blur(7px); transform: translateY(0px); opacity: 0.4;}
    25%         { filter: blur(3px); transform: translateY(-10px); opacity: 1;}
    50%         { filter: blur(5px); transform: translateY(-30px); opacity: 0.7;}
    75%         { filter: blur(5px); transform: translateY(-50px); opacity: 1;}
    100%        { filter: blur(7px); transform: translateY(-70px); opacity:0.3; }
  }
  @keyframes cup_steam {
    0%          { filter: blur(1px); transform: translateY(0px); opacity: 0; }
    25%         { filter: blur(3px); transform: translateY(-5px); opacity: 0.5; }
    50%         { filter: blur(5px); transform: translateY(-10px); opacity: 1; }
    75%         { filter: blur(5px); transform: translateY(-15px); opacity: 0.5; }
    100%        { filter: blur(7px); transform: translateY(-20px); opacity: 0; }  
  }  
  @keyframes phone_vibrate {
    0%, 2%, 4%, 6%, 8%, 10%, 12%, 14%, 16%, 18%   { transform: translate3d(-1px, 0, 0); }
    1%, 3%, 5%, 7%, 9%, 11%, 13%, 15%, 17%, 19%   { transform: translate3d(1px, 0, 0); }
    20%, 100%                                     { transform: translate3d(0, 0, 0); }
  }  
  @keyframes move_from_left {
    0%          { transform: translateX(-100vw); }
    80%         { transform: translateX(0vw); }
  }
  @keyframes move_from_right {
    0%          { transform: translateX(100vw); }
    80%         { transform: translateX(0vw); }
  }
  @keyframes fade_up {
    0%          { opacity: 1; transform: translateY(0px); }
    100%        { opacity: 0; transform: translateY(-20px); }
  }
  @keyframes fade_in {
    0%          { opacity: 0; }
    100%        { opacity: 1; }
  }
  @keyframes move_up {
    0%          { transform: translateY(0px); }
    50%, 80%    { transform: translateY(-10px);}
    81%         { transform: translateY(-10px);}
  }
  @keyframes rotate_random {
    0%, 19%          { transform: rotate(0deg); }
    20%              { transform: rotate(40deg); }
    100%             { transform: rotate(360deg); }
  }
  
  .slide-shell-1{
    // &:after{
    //   content: '';
    //   position: absolute;
    //   left: calc(50% - 75vw);
    //   right: 0;
    //   bottom:0;
    //   z-index: 0;
    //   background: rgb(240,240,240);
    //   background: linear-gradient(180deg, rgba(#ededed,1) 46%, rgba(200,200,200,1) 100%);
    //   transform: perspective(10px) rotateX(1deg);
    //   height: 67vh;
    //   width: 150vw;
    // }

    transition: all 1s ease-in-out;
    svg{
      #robot{
        animation: move_from_right 1s ease-out;
      }
      #macbook{
        animation: move_from_left 1.5s ease-out;
      }
      #food{
        animation: move_from_right 2s ease-out;
      }
      #chemex{
        animation: move_from_left 2.5s ease-out;
      }
      #aeropress{
        animation: move_from_right 3s ease-out;
      }
      #sketch-pad{
        animation: move_from_right 3.5s ease-out;
      }
      #headphones{
        animation: move_from_right 4s ease-out;
      }
      #mug{
        animation: move_from_left 4s ease-out;
      }
      #iphone{
        animation: move_from_left 3.5s ease-out;
      }
      #earbuds{
        animation: move_from_right 1.5s ease-out;
      }
      #cap{
        animation: move_from_right 2s ease-out;
      }
      #glasses{
        animation: move_from_right 2.5s ease-out;
      }
      #plant{
        animation: move_from_right 3s ease-out;
      }
      #trackpad{
        animation: move_from_left 2.5s ease-out;
      }
      #prezels{
        animation: move_from_left 3s ease-out;
      }
      #alexa{
        animation: move_from_right 3.5s ease-out;
      }
      #google{
        animation: move_from_right 4s ease-out;
      }
      #expo{
        animation: move_from_left 4s ease-out;
      }
      #head{
        animation: head_move 20s ease-out infinite;
        transform-box: fill-box;
        transform-origin: center;
      }
      #eyebrow-right{
        animation: eyebrow_move 20s infinite;
        animation-delay: 2s;
      }
      #eyebrow-left{
        animation: eyebrow_move 30s infinite;
        animation-delay: 6s;
      }
      #right-eye #lid,
      #left-eye #lid-2{
        transition: 0ms;
        opacity: 0;
        animation: eye_blink 5s infinite;
        animation-delay: 1s;
      }
      #right-eye #pupil,
      #left-eye #pupil-2{
        animation: eye_move 5s ease-out infinite;
        animation-delay: 1s;
      }
      #tongue{
        transition: all 0.5ms linear;;
        transform-box: fill-box;
        transform-origin: top center;
        animation: tongue_out 20s cubic-bezier(0.83, 0, 0.17, 1) infinite;
      }
      #bottom-half{
        animation: mouth_move 20s cubic-bezier(0.83, 0, 0.17, 1) infinite;
      }
      #antenna #light-on{
        opacity: 0;
        animation: light_flicker 20s linear infinite;
      }
      #steam {
        transition: all 0.5ms linear;;
        filter: blur(1px);
        animation: cup_steam 5s ease-out infinite;
      }
      #steam-2 {
        transition: all 0.5ms linear;
        filter: blur(1px);
        animation: chemex_steam 10s ease-out infinite;
      }
      #music_note_1 {
        animation: fade_up 2s ease-out infinite;
      }
      #music_note_2 {
        animation: fade_up 3s ease-out infinite;
      }
      #iphone #phone{
        animation: phone_vibrate 4s cubic-bezier(.36, .07, .19, .97) infinite;
      }
      #alexa #bubble{
        animation: move_up 4s ease-out infinite;
        animation-delay: 2s;
      }
      #google #bubble-2{
        animation: move_up 4s ease-out infinite;
        animation-delay: 3s;
      }
      #sign{
        animation: swing ease-in-out 1s infinite alternate;
        transition: all 4ms ease-in-out;
        transform-box: fill-box;
        transform-origin: top center;
        &:hover{
          transform: rotate(3deg);
        }
      }
      #burger{
        & > *{
          transition: transform 200ms ease-in-out;
          transform: translateY(0px);
          transform-box: fill-box;
          transform-origin: top center;
        }
        &:hover{
          #bun-top{
            transform: translateY(-50px) rotate(-5deg);
          }
          #tomato{
            transform: translateY(-40px) rotate(-4deg);
          }
          #lettuce{
            transform: translateY(-30px) rotate(6deg);
          }
          #cheese{
            transform: translateY(-20px) rotate(7deg);
          }
          #patty{
            transform: translateY(-10px) rotate(-6deg);
          }
          #bun-bottom{
            transform: translateY(0px);
          }
        }
      }
    }
  }
</style>
