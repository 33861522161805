import Vue from 'vue';

import HomeLoading              from './HomeLoading.vue';
import HomeHeader               from './HomeHeader.vue';
import HomeFooter               from './HomeFooter.vue';

import Book                     from './Book.vue';
import Slides                   from './Slides.vue';
import Slide1                   from './Slide1.vue';
import Slide2                   from './Slide2.vue';
import Slide3                   from './Slide3.vue';

Vue.component('v-home-loading',     HomeLoading);
Vue.component('v-home-header',      HomeHeader);
Vue.component('v-home-footer',      HomeFooter);

Vue.component('v-book',             Book);
Vue.component('v-slides',           Slides);
Vue.component('v-slide-1',          Slide1);
Vue.component('v-slide-2',          Slide2);
Vue.component('v-slide-3',          Slide3);

