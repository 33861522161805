<template>
  <div :class="['slide-shell','slide-shell-2']">
    <inline-svg :src="require('@/assets/slide-2.svg')"></inline-svg>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'Slide2Component',
  data() {
    return {
    }
  },
  computed:{
    rando(){
      let min, max;
      min = -5;
      max = 5;
      return Math.random() * (max - min) + min;
    }
  },
  components: {
    InlineSvg,
  },
}
</script>

<style lang="scss">
  @keyframes zoom_in_out {
    0%     { transform: none; }
    20%    { transform: rotate(-4deg); }
    40%    { transform: none; }
    60%    { transform: rotate(4deg); }
    80%     { transform: none; }
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  #circle-rotate{
    transform-box: fill-box;
    transform-origin: center;
    animation: rotating 50s linear infinite;
  }
  #tags{
    > #tag,
    > [id^="tag"]{
      transform-box: fill-box;
      transform-origin: top center;
      animation: zoom_in_out 2s infinite linear;
    }
  }
  .slide-shell-2{
    transition: all 1s ease-in-out;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
</style>
