<template>
  <header>
    <h1>Kristopher Khoury</h1>
    <hr>
    <h3>Web Developer | Software Architect | UI/UX</h3>
  </header>
</template>

<script>
export default {
  name: 'HomeHeader',
  data() {
    return {
    }
  },
  created(){
  }
}
</script>

<style lang="scss">
  header{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 80px 0px 0px;
    text-align: center;
    z-index: 200;
    @media (max-height: 800px) {
      padding: 40px 0px 0px;
    }
    h1{
      margin: 0;
      line-height: 1em;
      font:{
        family: 'Onest', sans-serif;
        weight: 900;
        size: 60px;
      }
      letter-spacing: -2px;
      @media (max-width: 700px) {
        font:{
          size: 40px;
        }
      }
    }
    hr{
      position: relative;
      width: 500px;
      margin: 10px auto;
      border: none;
      border-bottom: solid 4px black;
      overflow: visible;
      &:before{
        content: '\25C9';
        position: absolute;
        top: -12px;
        left: -17px;
      }
      &:after{
        content: '\25C9';
        position: absolute;
        top: -12px;
        right: -17px;
      }
    }
    h3{
      margin: 0;
      line-height: 1em;
      font:{
        family: 'Onest', sans-serif;
        weight: 600;
        size: 24px;
      }
      @media (max-width: 700px) {
        font:{
          size: 15px;
        }
      }
    }
  }

</style>
