<template>
  <footer>
    <ul>
      <li><a href="https://www.github.com/kriskhoury" target=_blank><font-awesome-icon :icon="['fab', 'github']" /></a></li>
      <li><a href="https://www.linkedin.com/in/kriskhoury" target=_blank><font-awesome-icon :icon="['fab', 'linkedin']" /></a></li>
      <li><a href="https://www.medium.com/kriskhoury" target=_blank><font-awesome-icon :icon="['fab', 'medium']" /></a></li>
      <li><a href="https://www.instagram.com/kriskhoury" target=_blank><font-awesome-icon :icon="['fab', 'instagram']" /></a></li>
      <li><a href="mailto:kris@kriskhoury.com" target=_blank><font-awesome-icon :icon="['fa', 'envelope']" /></a></li>
      <!-- <li>
        <button @click="play" type="button">
        </button>
      </li>
      <li>
        <button @click="pause" type="button">
        </button>
      </li> -->
    </ul>
    <div>© 2023 KrisKhoury.com</div>
  </footer>
</template>

<script>
export default {
  name: 'HomeFooter',
  data() {
    return {
      audio: null,
      isPlaying: false,
      musicSrc: require('@/assets/jazz-happy-110855.mp3'),
    }
  },
  created(){
    // this.loadMusic();
  },
  methods:{
    loadMusic(){
      let self = this;
      const tryToPlay = setInterval(() => {
        self.audio = new Audio(this.musicSrc);
        self.audio.volume = 0.1;
        
        addEventListener("ended", function(){
          self.audio.currentTime = 0;
          self.isPlaying = false;
        });

        if(!self.isPlaying && self.audio){
          self.audio.play()
            .then(() => {
              clearInterval(tryToPlay);
              self.isPlaying = true;
            })
            .catch(() => {
              console.info('User has not interacted with document yet.');
            });
        }
      }, 5000);
    },
    play() {
      if(!this.isPlaying){
        this.audio.volume = 0.1;
        this.audio.play();
        this.isPlaying = true;
      }
    },
    pause() {
      if(this.isPlaying){
        this.audio.pause();
        this.isPlaying = false;
      }
    }
  }

}
</script>

<style lang="scss">
  footer{
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    padding: 20px;
    z-index: 99;
    ul{
      list-style: none;
      display: inline-flex;
      justify-content: center;
      padding: 0 20px;
      margin: 0 auto 20px;
      background-color: #000;
      border-radius: 20px;
      li{
        padding: 10px;
        font-size: 30px;
        a{
          svg{
            transition: all 0.2s ease-in-out;
          }
          color: #fff;
          &:hover{
            svg{
              transform: scale(1.6);
            }
          }
        }
      }
    }
  }

</style>
