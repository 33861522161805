<template>
  <div>
    <div :class="['slides']" :data-slide="this.slide" :style="{'left': `-`+(this.slide*100)+`vw`}" v-touch:swipe.left="next" v-touch:swipe.right="prev">
      <v-slide-1></v-slide-1>
      <!-- <v-slide-2></v-slide-2>
      <v-slide-3></v-slide-3> -->
    </div>
    <!-- <div class="actions">
      <a href="#" class="btn-left" @click="prev">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="fa-2xl" />
      </a>
      <a href="#" class="btn-right" @click="next">
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="fa-2xl" />
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'SlidesComponent',
  data() {
    return {
      slide: 0,
      slides: 1,
    }
  },
  methods:{
    next(){
      // if(this.slide+1 == this.slides){
      //   return;
      // }
      // this.slide++;
    },
    prev(){
      // if(this.slide == 0){
      //   return;
      // }
      // this.slide--;
    }
  }
}
</script>

<style lang="scss">
.slides{
  transition: all 1s cubic-bezier(0.83, 0, 0.17, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  > div{
    flex: 0 0 100vw;
    position: relative;
    width: 100vw;
    height: 100vh;
    > svg{
      position: relative;
      z-index: 1;
      width: 100vw;
      height: 99vh;
    }
  }
}
.actions{
  .btn-left,
  .btn-right{
    position: absolute;
    z-index: 100;
    bottom: 0px;
    background-color: black;
    width: 60px;
    height: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-left{
    left: 0px;
  }
  .btn-right{
    right: 0px;
  }
}
</style>
